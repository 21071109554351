import { BlobServiceClient } from "@azure/storage-blob";
import html2canvas from "html2canvas";
import { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  AttendanceBalanceApi,
  AttendanceRequestApi,
  ErrorHandler,
} from "src/system/ApiService";
import * as yup from "yup";
import { AttendanceDocumentForm } from "../PostAttendanceRequest";
import {
  CreateAttendanceDocument,
  GetAttendanceForm,
} from "../interface/RequestInterface";
import { attendanceBalanceCountMapAtom } from "../recoils/AttendanceMapAtom";
import { saveLoadingModalAtom } from "../recoils/SaveLoadingModalAtom";
import { v4 } from "uuid";

export const useAttendanceHooks = () => {
  const resetSavingLoadingModalState =
    useResetRecoilState(saveLoadingModalAtom);
  const [attendanceDataState, setAttendanceDataState] = useRecoilState(
    attendanceBalanceCountMapAtom
  );
  const setSaveLoadingModalState = useSetRecoilState(saveLoadingModalAtom);

  /**
   * @type 스키마
   * attendanceRequestSchema
   */
  const attendanceRequestSchema = yup.object({
    attendanceItem: yup
      .array()
      .of(
        yup.object({
          workDate: yup
            .string()
            .trim()
            .required("신청일이 지정되지 않았습니다."),
          startTime: yup
            .string()
            .trim()
            .test("startTime", "시작시간이 비어있습니다.", (value, context) => {
              const isTimeEntered = context.parent.isTimeEntered;
              const term = context.parent.term as number;
              if (isTimeEntered && term !== -1 && value === "") {
                return false;
              }
              return true;
            }),
          endTime: yup
            .string()
            .trim()
            .test("endTime", "종료시간이 비어있습니다.", (value, context) => {
              const isTimeEntered = context.parent.isTimeEntered;
              const term = context.parent.term as number;
              if (isTimeEntered && term !== -1 && value === "") {
                return false;
              }
              return true;
            }),
          vehicle: yup
            .string()
            .trim()
            .test(
              "isVehicleEmpty",
              "차량을 선택해주세요.",
              (value, context) => {
                const isCheckOwnVehicle =
                  context.parent.attendanceForm.isCheckOwnVehicle;
                if (isCheckOwnVehicle && !value) {
                  return false;
                }
                return true;
              }
            ),
        })
      )
      .test(
        "attendanceItem",
        "양식 선택후, 반영버튼을 눌러주세요.",
        (value, context) => {
          if (
            (!value || value.length === 0) &&
            context.parent.attendanceRequestItemCancelIdList.length === 0
          ) {
            return false;
          }
          return true;
        }
      ),
    attendanceRequestItemCancelIdList: yup
      .array()
      .of(yup.number())
      .test(
        "cancelIdList",
        "취소할 연차를 하나 이상 선택해주세요.",
        (value, context) => {
          if (
            (!value || value.length === 0) &&
            context.parent.attendanceItem.length === 0
          ) {
            return false;
          }
          return true;
        }
      ),
  });

  /**
   * @public
   * 통근태 양식 List Fetch
   * @returns GetAttendanceForm[] | []
   */
  const useFetchAttendanceFormList = () => {
    const [formList, setFormList] = useState<GetAttendanceForm[]>([]);

    useEffect(() => {
      AttendanceRequestApi.GetAttendanceFormList(true)
        .then((res) => setFormList(res))
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }, []);

    return formList;
  };

  /**
   * 양식 생성 method 캡쳐 => 업로드 => 생성
   * @public
   * @param data
   */
  const submitFormData = async (
    data: AttendanceDocumentForm,
    formReset: () => void,
    employeeId: string
  ) => {
    let documentId: number | null = null;
    try {
      setSaveLoadingModalState({
        isOpen: true,
        text: "문서 작성 및 결재사이트로 이동중입니다. 잠시만 기다려주세요.",
      });

      const scale = data.attendanceItem.length > 120 ? 2 : 4;
      const canvas = await getCapturedCanvasPromise(scale);
      const captureImgBlobUrl = await uploadCanvasToBlob(canvas, employeeId);

      const request: CreateAttendanceDocument = {
        attendanceRequestItemList: data.attendanceItem.map((item) => {
          return {
            attendanceRequestItemFormId: item.attendanceForm.id,
            workDate: item.workDate,
            started: item.workDate + " " + item.startTime,
            ended: item.workDate + " " + item.endTime,
            vehicle: item.vehicle,
            employeeId: item.employeeId,
          };
        }),
        status: 0,
        title: data.title,
        reason: data.reason,
        call: data.call,
        htmlContent: captureImgBlobUrl,
        attendanceRequestItemCancelIdList:
          data.attendanceRequestItemCancelIdList,
      };

      const response = await AttendanceRequestApi.CreateAttendanceDocument(
        request
      );
      documentId = response.id;

      await fetchDocumentDetail(response.id, 0);
      formReset();
    } catch (error) {
      resetSavingLoadingModalState();

      const errorMessage = documentId
        ? "그룹웨어(결재사이트) 임시저장함으로 이동합니다.\n통합근태 문서가 생성되지 않았을 경우, 인프라개발팀으로 문의해주시기 바랍니다."
        : "문서 생성 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요";

      alert(errorMessage);

      if (documentId) {
        window.location.href =
          "https://groupware.weareinbody.com/document/temporary-box";
      }
    }
  };

  /**
   * @private
   * 통근태 작성 문서 스크린샷 찍는 함수
   * exclude class는 포함 X
   * disabled는 전부 제거후에 스샷(색 통일하기 위해서)
   * @return Promise<HTMLCanvasElement>
   */
  const getCapturedCanvasPromise = (
    scale: number
  ): Promise<HTMLCanvasElement> => {
    return html2canvas(document.querySelector("#capture")!, {
      onclone: (document) => {
        const syncfusionDisabledElements =
          document.querySelectorAll(".e-disabled");
        const materialDisabledElements =
          document.querySelectorAll(".Mui-disabled");
        syncfusionDisabledElements.forEach((element) =>
          element.classList.remove("e-disabled")
        );
        materialDisabledElements.forEach((element) =>
          element.classList.remove("Mui-disabled")
        );

        const textAreaElement = document.querySelector("textarea");
        if (textAreaElement) {
          const div = document.createElement("div");
          div.setAttribute(
            "style",
            "min-height: 38px; padding: 10px 12px 9px; white-space : pre-line; text-align: left;"
          );
          div.innerText = textAreaElement.value;
          textAreaElement.replaceWith(div);
        }

        const captureElement = document.querySelector("#capture");
        if (captureElement) {
          (captureElement as HTMLElement).style.border = "2px solid #EDEDED";
        }
      },
      ignoreElements: (element) => {
        return element.classList.contains("exclude");
      },
      scale: scale,
    });
  };

  /**
   * @private
   * SasToken으로 BlobClient 얻는 함수
   * @return blobClient
   */
  const getBlobClientBySasToken = (
    sasToken: string,
    uploadFileName: string
  ) => {
    const blobService = new BlobServiceClient(sasToken);
    const containerClient = blobService.getContainerClient("");
    return containerClient.getBlockBlobClient(uploadFileName);
  };

  /**
   * @private
   * Canvas에서 blob얻는 함수
   * @param canvas HTMLCanvasElement
   * @returns Promise<Blob|null>
   */
  const canvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
    return new Promise((resolve) => {
      canvas.toBlob((blob) => resolve(blob));
    });
  };

  /**
   * @private
   * blob파일 업로드 후, url 반납하는 함수
   * @return 업로드한 파일의 Blob Url
   */
  const uploadCanvasToBlob = async (
    canvas: HTMLCanvasElement,
    employeeId: string
  ): Promise<string> => {
    const blob = await canvasToBlob(canvas);
    if (!blob) throw new Error("캔버스 파일 생성 실패!");

    const sasToken = await AttendanceRequestApi.GetSasToken();
    const blobClient = getBlobClientBySasToken(
      sasToken,
      `${employeeId}_${v4()}.jpg`
    );

    await blobClient.uploadData(blob);

    await blobClient.setTags({ employeeId: employeeId });

    return blobClient.url.split("?")[0];
  };

  /**
   * @private
   * 통근태 request 생성 -> 결재 사이트와 연동 확인(url) -> 결재사이트로 이동
   * @param attempt 1초마다 조회 => 10초까지
   */
  const fetchDocumentDetail = async (
    documentId: number,
    attempt: number = 0
  ) => {
    const MAX_ATTEMPTS = 10;
    const RETRY_INTERVAL = 1000;

    try {
      const res = await AttendanceRequestApi.GetAttendanceDocumentDetail(
        documentId
      );

      if (res.documentUrl) {
        resetSavingLoadingModalState();

        window.location.href = res.documentUrl.replace(
          "detail-document",
          "modify-document"
        );
        return;
      }

      if (attempt < MAX_ATTEMPTS) {
        setTimeout(
          () => fetchDocumentDetail(documentId, attempt + 1),
          RETRY_INTERVAL
        );
      } else {
        resetSavingLoadingModalState();
        alert(
          "그룹웨어(결재사이트) 임시저장함으로 이동합니다.\n통합근태 문서가 생성되지 않았을 경우, 인프라개발팀으로 문의해주시기 바랍니다."
        );
        window.location.href =
          "https://groupware.weareinbody.com/document/temporary-box";
      }
    } catch (error) {
      resetSavingLoadingModalState();
      alert(
        "그룹웨어(결재사이트) 임시저장함으로 이동합니다.\n통합근태 문서가 생성되지 않았을 경우, 인프라개발팀으로 문의해주시기 바랍니다."
      );
      window.location.href =
        "https://groupware.weareinbody.com/document/temporary-box";
    }
  };

  /**
   * 임직원의 연차 현황(총,사용)을 구한 후 Recoil에서 Map으로 관리 - key:사번
   * 사번에 맞는 연차 데이터 없으면 Fetching, 있다면 데이터 반납
   * @async
   * @private
   * @param employeeId 사번
   * @returns
   */
  const getAttendanceBalanceCountData = async (employeeId: string) => {
    const attendanceData = attendanceDataState.get(employeeId);

    if (!attendanceData) {
      const attendanceBalanceCount =
        await AttendanceBalanceApi.GetAttendanceBalanceCount(employeeId);

      // recoil에 새로운 데이터를 추가하여 map을 set
      setAttendanceDataState((pre) => {
        const updatedMap = new Map(pre);
        updatedMap.set(employeeId, attendanceBalanceCount);
        return updatedMap;
      });

      return attendanceBalanceCount;
    }

    return attendanceData;
  };

  return {
    attendanceRequestSchema,
    useFetchAttendanceFormList,
    submitFormData,
    getAttendanceBalanceCountData,
  };
};
